import React from "react"

import Layout from "../components/layout"
import { Link } from "gatsby"

const ThankYouPage = () => (
  <Layout>
    <section className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
      <h1 className="mt-1 text-4xl tracking-tight leading-10 font-medium text-gray-900 sm:leading-none sm:text-3xl lg:text-4xl xl:text-5xl">
        Thank you!
      </h1>
      <p className="my-3 text-base text-gray-500 sm:mt-5">
        Your form submission has been received.
      </p>
      <Link to="/" className="underline text-blue-400 hover:text-blue-500">
        Back to homepage
      </Link>
    </section>
  </Layout>
)

export default ThankYouPage
